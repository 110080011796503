// TODO: Fix weird focus state issues
const PopupContent = (props) => {
  return (
    <>
      <p>
        <strong>Date</strong> <span>{props.feature?.date}</span>
      </p>
      <p>
        <strong>Victim</strong> <span>{props.feature?.victimName}</span>
      </p>
      <p>
        <strong>Officers</strong>{" "}
        <span>
          {JSON.parse(props.feature?.officerNames || `[]`).join(", ")}
        </span>
      </p>
      <p>
        <a href={`/archive/${props.feature?.slug}`}>See more details</a>
      </p>
    </>
  )
}

export default PopupContent
