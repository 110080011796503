import { onCleanup, onMount } from "solid-js"
import { usePopup } from "../providers/popup"

const compactAttribControl = () => {
  const control = document.querySelector("details.maplibregl-ctrl-attrib")
  control.removeAttribute("open")
  control.classList.remove("mapboxgl-compact-show", "maplibregl-compact-show")
}

const Map = (props) => {
  let map
  let mapRef

  const [, setPopup] = usePopup()

  onMount(() => {
    map = new window.maplibregl.Map({
      container: mapRef,
      ...props.mapOptions,
    })
    map.touchZoomRotate.disableRotation()

    map.addControl(
      new window.maplibregl.AttributionControl({
        compact: props.isMobile,
      }),
      props.isMobile ? "top-left" : "bottom-right"
    )
    // Workaround for a bug in maplibre-gl where the attrib is default open
    if (props.isMobile) {
      compactAttribControl()
      const timeouts = [250, 500, 1000]
      timeouts.forEach((timeout) => {
        window.setTimeout(compactAttribControl, timeout)
      })
    }
    map.once("styledata", () => {
      map.addControl(
        new window.maplibregl.NavigationControl({ showCompass: false })
      )
      map.addControl(
        new window.maplibregl.FullscreenControl({ container: mapRef })
      )
      map.resize()
    })

    setPopup({ map })
  })

  onCleanup(() => {
    map.remove()
  })

  return <div id="map" ref={mapRef} />
}

export default Map
