import { Show, createEffect } from "solid-js"
import Map from "../components/map"
import Popup from "../components/popup"
import PopupContent from "../components/popup-content"
import Geocoder from "../components/geocoder"
import { usePopup } from "../providers/popup"
import { updateQueryParams } from "../utils"

const EMBED_MOBILE_CUTOFF = 500
const MOBILE_CUTOFF = 800

const MapPage = () => {
  // const [state, setState] = createStore({})
  const [popup, setPopup] = usePopup()

  createEffect(() => {
    updateQueryParams({})
  })

  const isEmbedded = document.documentElement.classList.contains("embedded")
  const isMobile =
    window.innerWidth < (isEmbedded ? EMBED_MOBILE_CUTOFF : MOBILE_CUTOFF)

  const mapViewOptions = isMobile
    ? { zoom: 9, center: [-87.7131, 41.7941] }
    : { zoom: 9.8, center: [-87.6465, 41.8364] }

  const azureMapsKey = document.head.querySelector(
    `meta[name="azure-maps-key"]`
  ).content

  return (
    <>
      <Map
        isMobile={isMobile}
        mapOptions={{
          style: "style.json",
          center: [-87.6651, 41.8514],
          minZoom: 8,
          maxZoom: 15,
          zoom: 9.25,
          hash: true,
          dragRotate: false,
          attributionControl: true,
          ...mapViewOptions,
        }}
      />
      <div id="legend">
        <div id="geocoder-container">
          <Geocoder
            azureMapsKey={azureMapsKey}
            onSelect={(selectedPoint) => setPopup({ selectedPoint })}
          />
        </div>
        <div class="content">
          <h1>PARC</h1>
          <form method="GET" action="" />
          <div>Legend TBD</div>
        </div>
      </div>
      <Show when={popup.map}>
        <Popup
          map={popup.map}
          layer={"cases"}
          source={"cases"}
          active={popup.click || popup.hover}
          lngLat={popup.lngLat}
        >
          <PopupContent feature={popup.feature} />
        </Popup>
      </Show>
    </>
  )
}

export default MapPage
