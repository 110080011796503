const formatDate = (dateStr) => {
  if (!dateStr) return ``
  const [year, month, day] = dateStr.split("-")
  const date = new Date(+year, +month - 1, +day)
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

const formatList = (officerNames) =>
  officerNames.length < 3
    ? officerNames.join(" and ")
    : `${officerNames.slice(0, officerNames.length - 1).join(", ")}, and ${
        officerNames[officerNames.length - 1]
      }`

const Result = (props) => {
  return (
    <div class="filter-result">
      <p>
        <a href={`/archive/${props.slug}`}>{props.victimName}</a>{" "}
        {props.officerNames.length > 0
          ? `was killed by ${formatList(props.officerNames)}`
          : ``}{" "}
        on {formatDate(props.date)}
      </p>
    </div>
  )
}

export default Result
