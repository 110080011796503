import { createMemo, onMount, For } from "solid-js"
import { createStore } from "solid-js/store"
import { csvParse } from "d3-dsv"
import Fuse from "fuse.js/dist/fuse.basic.esm"
import SearchIcon from "../components/search-icon"

const MAX_RESULTS = 5

const NavSearch = (props) => {
  const [state, setState] = createStore({
    search: props.initialSearch || ``,
    expanded: false,
    activeIndex: -1,
  })

  let data = []

  onMount(() => {
    fetch("/cases.csv")
      .then((res) => res.text())
      .then((res) => {
        data = csvParse(res.trim(), ({ officerNames, ...row }) => ({
          ...row,
          officerNames: officerNames.split(","),
        }))
      })
  })

  const results = createMemo(() =>
    (state.search.trim()
      ? new Fuse(data, {
          keys: [`victimName`, `officerNames`],
          threshold: 0.3,
        })
          .search(state.search.trim())
          .map(({ item }) => item)
      : data
    ).slice(0, MAX_RESULTS)
  )

  const onKeyDown = (e) => {
    if (["ArrowDown", "ArrowRight"].includes(e.code)) {
      if (e.code === "ArrowDown" || state.activeIndex > -1) e.preventDefault()
      if (state.activeIndex < results().length - 1) {
        setState({ activeIndex: state.activeIndex + 1 })
      }
    } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
      if (e.code === "ArrowUp" || state.activeIndex > -1) e.preventDefault()
      if (state.activeIndex > -1) {
        setState({ activeIndex: state.activeIndex - 1 })
      }
    } else if (e.code === "Enter") {
      e.preventDefault()
      if (state.activeIndex > -1) {
        window.location.href = `/archive/${results()[state.activeIndex].slug}`
      }
    } else if (e.code === "Space") {
      if (state.activeIndex > -1) {
        e.preventDefault()
        window.location.href = `/archive/${results()[state.activeIndex].slug}`
      }
    }
  }

  return (
    <>
      <div class="search-input">
        <SearchIcon />
        <input
          type="search"
          name="search"
          placeholder="Search"
          aria-label="Search"
          autocomplete="off"
          role="combobox"
          aria-haspopup="listbox"
          aria-autocomplete="list"
          aria-controls="nav-search-results"
          aria-expanded={state.expanded}
          aria-activedescendant={
            state.activeIndex >= 0 ? `result-${state.activeIndex}` : ``
          }
          value={state.search}
          onInput={(e) => setState({ search: e.target.value, expanded: true })}
          onKeyDown={onKeyDown}
          onBlur={() =>
            window.setTimeout(
              () => setState({ activeIndex: -1, expanded: false }),
              100
            )
          }
        />
        <button
          type="button"
          class={`delete ${state.search.trim() ? `` : `hidden`}`}
          aria-label="Clear search input"
          onClick={() => setState({ search: "", activeIndex: -1 })}
        />
      </div>
      <ul
        id="nav-search-results"
        role="listbox"
        class={state.expanded ? `` : `hidden`}
      >
        <For each={results()}>
          {(result, idx) => (
            <li
              id={`result-${idx()}`}
              role="option"
              class="result"
              aria-selected={state.activeIndex === idx() || null}
            >
              <a href={`/archive/${result.slug}/`}>{result.victimName}</a>
            </li>
          )}
        </For>
      </ul>
    </>
  )
}

export default NavSearch
