import { render } from "solid-js/web"
import NavSearch from "./components/nav-search"

function setupNavToggle() {
  const navToggle = document.getElementById("nav-toggle")

  navToggle.addEventListener("click", () => {
    const expanded = navToggle.getAttribute("aria-expanded")
    navToggle.setAttribute("aria-expanded", !(expanded === "true"))
  })
}

setupNavToggle()

const navSearchEl = document.getElementById("nav-search-form")
if (navSearchEl) {
  render(() => <NavSearch />, navSearchEl)
}
