import { createEffect, createMemo, createSignal, For, Show } from "solid-js"
import { createStore } from "solid-js/store"
import Fuse from "fuse.js/dist/fuse.basic.esm"
import { createIntersectionObserver } from "@solid-primitives/intersection-observer"
import { updateQueryParams } from "../utils"
import Result from "../components/result"
import SearchIcon from "../components/search-icon"
import UpArrowIcon from "../components/up-arrow-icon"

const PAGE_SIZE = 20

function filterResults(data, search, after, before) {
  let results = data
  if (after || before) {
    results = results.filter(({ date }) =>
      after ? date >= after : true && before ? date <= before : true
    )
  }
  results = search.trim()
    ? new Fuse(results, {
        keys: [`victimName`, `officerNames`],
        threshold: 0.3,
      })
        .search(search.trim())
        .map(({ item }) => item)
    : results

  return results
}

function filtersHaveValues(filters) {
  return !!Object.entries(filters).find(([key, value]) =>
    key === `page` ? value > 1 : !!value
  )
}

const Filters = (props) => {
  const [state, setState] = createStore({
    search: props.initialSearch || ``,
    page: +props.initialPage || 1,
    after: props.initialAfter || ``,
    before: props.initialBefore || ``,
    showScrollTop: false,
  })

  const results = createMemo(() =>
    filterResults(props.data, state.search, state.after, state.before)
  )

  const [targets, setTargets] = createSignal([])

  createIntersectionObserver(targets, (entries) => {
    const resultsEnd = entries.find(({ target }) => target.id === "results-end")
    const filterForm = entries.find(({ target }) =>
      target.classList.contains("archive-form")
    )
    if (resultsEnd?.isIntersecting) {
      setState({ page: state.page + 1 })
    }
    if (filterForm) {
      setState({ showScrollTop: !filterForm?.isIntersecting })
    }
  })

  createEffect(() => {
    updateQueryParams({
      search: state.search,
      after: state.after,
      before: state.before,
    })
  })

  // Helper to make sure the page resets whenever filters change
  const setFilters = (filters) => setState({ ...filters, page: 1 })

  return (
    <>
      <Show when={state.showScrollTop}>
        <button
          aria-label="Scroll to top"
          id="scroll-to-top"
          onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}
        >
          <UpArrowIcon />
        </button>
      </Show>
      <form
        class="archive-form"
        action=""
        method="GET"
        ref={(el) => setTargets((e) => [...e, el])}
      >
        <div class="search-input">
          <SearchIcon />
          <input
            type="search"
            name="search"
            placeholder="Search"
            aria-label="Search"
            value={state.search}
            onInput={(e) => setFilters({ search: e.target.value })}
          />
        </div>
        <div class="date-filters">
          <div class="date-filter">
            <label for="after-date">After Date</label>
            <input
              type="date"
              name="after"
              id="after-date"
              value={state.after}
              onChange={(e) => setFilters({ after: e.target.value })}
            />
          </div>
          <div class="date-filter">
            <label for="before-date">Before Date</label>
            <input
              type="date"
              name="before"
              id="before-date"
              value={state.before}
              onChange={(e) => setFilters({ before: e.target.value })}
            />
          </div>
        </div>
        <div class="results-row">
          <p aria-live="polite" aria-atomic="true" class="result-count">
            {`${results().length.toLocaleString()} ${
              results().length === 1 ? `result` : `results`
            }`}
          </p>
          {filtersHaveValues(state) && (
            <button
              type="button"
              onClick={() =>
                setState({ search: ``, after: ``, before: ``, page: 1 })
              }
            >
              Clear filters
            </button>
          )}
        </div>
      </form>
      <div class="filter-results">
        <For each={results().slice(0, PAGE_SIZE * state.page)}>
          {(result) => (
            <Result
              victimName={result.victimName}
              officerNames={result.officerNames}
              date={result.date}
              slug={result.slug}
            />
          )}
        </For>
      </div>
      <Show when={state.page < Math.ceil(results().length / PAGE_SIZE)}>
        <button
          ref={(el) => setTargets((e) => [...e, el])}
          type="button"
          id="results-end"
          class="visually-hidden"
          onClick={() => {
            // TODO: Check this on load, when clicking back intersection observer
            // won't fire because not changed
            setState({ page: state.page + 1 })
            // Imperfect, seems to skip from jumping around, but moves focus forward
            document
              .querySelector(
                `.filter-results .filter-result:nth-child(${
                  state.page * PAGE_SIZE
                }) a`
              )
              .focus()
          }}
        >
          Show more
        </button>
      </Show>
    </>
  )
}

export default Filters
