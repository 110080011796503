import { render } from "solid-js/web"
import { csvParse } from "d3-dsv"
import Filters from "./pages/filters"

function renderArchive(dataEl) {
  const archiveData = csvParse(
    dataEl.innerText.trim(),
    ({ officerNames, ...row }) => ({
      ...row,
      officerNames: officerNames.trim() ? officerNames.split(",") : [],
    })
  )
  const params = new URLSearchParams(window.location.search)

  render(
    () => (
      <Filters
        data={archiveData}
        initialSearch={params.get("search")}
        initialPage={params.get("page")}
        initialAfter={params.get("after")}
        initialBefore={params.get("before")}
      />
    ),
    document.getElementById("filters")
  )
}

const dataEl = document.getElementById("archive-data")
if (dataEl) {
  renderArchive(dataEl)
}
