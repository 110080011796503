import { render } from "solid-js/web"

import PopupProvider from "./providers/popup"
import MapPage from "./pages/map-page"

const mapContainer = document.getElementById("map")

// const params = new URLSearchParams(window.location.search)

if (mapContainer) {
  render(
    () => (
      <PopupProvider>
        <MapPage />
      </PopupProvider>
    ),
    mapContainer
  )
}
